<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="duck"  contain height="200" />
      </v-col>

      <v-col>
        <h1>Congratulations you found the hidden duck!</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="4" md="3" lg="2" v-if="showLargeJimmyJohns">
        <v-img :src="jimmyJohns" contain height="200" />

        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Jimmy John's Meal</div>
          <v-btn class="primary mt-3" @click="claimJimmyJohns()"
            >Claim Reward</v-btn
          >
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="3" lg="2" v-if="showLargeReadBooks">
        <v-img :src="readbooks2" contain height="200" />

        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">New Book</div>
          <v-btn class="primary mt-3" @click="claimNewBooks()"
            >Claim Reward</v-btn
          >
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="3" lg="2" v-if="showLargeDateNight">
        <v-img :src="datenight" contain height="200" />

        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Date Night</div>
          <v-btn class="primary mt-3" @click="claimDateNight()"
            >Claim Reward</v-btn
          >
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="3" lg="2" v-if="showLargeStarBucksDrink">
        <v-img :src="starbucksDrink" contain height="200" />

        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Starbucks Drink</div>
          <v-btn class="primary mt-3" @click="claimStarbucksDrink()"
            >Claim Reward</v-btn
          >
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="3" lg="2" v-if="showLargeCookieMonster">
        <v-img :src="cookieMonster" contain height="200" />

        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Cookie Monster</div>
          <v-btn class="primary mt-3" @click="claimCookieMonster()"
            >Claim Reward</v-btn
          >
        </v-card-text>
      </v-col>

      <v-col cols="12" sm="4" md="3" lg="2" v-if="showLargeHiking">
        <v-img :src="hiking" contain height="200" />

        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Go Hiking</div>
          <v-btn class="primary mt-3" @click="claimHiking()"
            >Claim Reward</v-btn
          >
        </v-card-text>
      </v-col>
    </v-row>

    <v-row v-if="rewards">
      <v-col cols="12">
        <h2>Rewards Claimed</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2" v-if="miniJimmyJohns">
        <v-img :src="jimmyJohns" contain height="100" />
        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Jimmy John's Meal</div>
        </v-card-text>
      </v-col>

      <v-col cols="2" v-if="miniReadBooks">
        <v-img :src="readbooks2" contain height="100" />
        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">New Book</div>
        </v-card-text>
      </v-col>

      <v-col cols="2" v-if="miniDateNight">
        <v-img :src="datenight" contain height="100" />
        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Date Night</div>
        </v-card-text>
      </v-col>

      <v-col cols="2" v-if="miniStarBucksDrink">
        <v-img :src="starbucksDrink" contain height="100" />
        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Starbucks Drink</div>
        </v-card-text>
      </v-col>

      <v-col cols="2" v-if="miniCookieMonster">
        <v-img :src="cookieMonster" contain height="100" />
        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Cookie Monster</div>
        </v-card-text>
      </v-col>

      <v-col cols="2" v-if="miniHiking">
        <v-img :src="hiking" contain height="100" />
        <v-card-text class="text--primary text-center">
          <div class="mb-4 font-weight-bold">Go Hiking</div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import duck from '@/assets/images/Duck.png'
import jimmyJohns from '@/assets/images/jimmyJohns.png'
import starbucksDrink from '@/assets/images/starbucksDrink.png'
import readbooks2 from '@/assets/images/readbooks2.png'
import datenight from '@/assets/images/datenight2.png'
import cookieMonster from '@/assets/images/cookieMonster.png'
import hiking from '@/assets/images/hiking2.png'

export default {
  name: 'Landing',

  data() {
    return {
      duck: duck,
      rewards: false,

      jimmyJohns: jimmyJohns,
      showLargeJimmyJohns: true,
      miniJimmyJohns: false,

      readbooks2: readbooks2,
      showLargeReadBooks: true,
      miniReadBooks: false,

      datenight: datenight,
      showLargeDateNight: true,
      miniDateNight: false,

      starbucksDrink: starbucksDrink,
      showLargeStarBucksDrink: true,
      miniStarBucksDrink: false,

      cookieMonster: cookieMonster,
      showLargeCookieMonster: true,
      miniCookieMonster: false,

      hiking: hiking,
      showLargeHiking: true,
      miniHiking: false,
    }
  },

  methods: {
    claimJimmyJohns() {
      this.miniJimmyJohns = true
      this.showLargeJimmyJohns = false
      this.rewards = true
    },

    claimNewBooks() {
      this.miniReadBooks = true
      this.showLargeReadBooks = false
      this.rewards = true
    },

    claimDateNight() {
      this.miniDateNight = true
      this.showLargeDateNight = false
      this.rewards = true
    },

    claimStarbucksDrink() {
      this.miniStarBucksDrink = true
      this.showLargeStarBucksDrink = false
      this.rewards = true
    },

    claimCookieMonster() {
      this.miniCookieMonster = true
      this.showLargeCookieMonster = false
      this.rewards = true
    },

    claimHiking() {
      this.miniHiking = true
      this.showLargeHiking = false
      this.rewards = true
    },
  },
}
</script>

<style scoped>
a.link {
  text-decoration: none;
}
</style>
